<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Vår anläggning</h1>
      <p>
        Vi har inte bara många olika banor och en stor fordonspark, vi har även
        flera olika byggnader varav en man kan hyra.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <article>
        <h3>Huset</h3>
        <p>På gården finns ett tvåvåningshus med 8 bäddar som går att hyra.</p>
        <router-link to="/rent-house" class="link-4">Läs mer här</router-link>
      </article>
      <article>
        <h3>Däckbanor</h3>
        <p>
          Vi har 3 stycken upplysta däckbanor i olika storlekar och olika
          svårighetsgrader.
        </p>
      </article>
      <article>
        <h3>Jordbana</h3>
        <p>Vi har en upplyst crossbana som hör ihop med vårat endurospår.</p>
      </article>
      <article>
        <h3>Endurospår</h3>
        <p>
          Vi har ett långt endurospår där man får prova på alla möjliga element
          såsom rötter, lera, sand, sten och berg.
        </p>
      </article>
      <article>
        <h3>Belysning</h3>
        <p>
          Vi har gott om belysning på anläggningen, vilket gör det möjligt att
          köra cross även när det är mörkt ute.
        </p>
        <p>
          Om du vill tända belysningen finns våra kontaktuppgifter under:
          <router-link to="/info" class="link-4">Uppgifter</router-link>
        </p>
      </article>
      <article id="kiosk">
        <h3>Kiosk/klubbstuga</h3>
        <p>
          Vanligtvis är kiosken öppen under måndagsaktiviteter och andra
          aktiviteter, med servering i klubbstugan. Där finns kaffe, läsk, korv,
          godis mm.
        </p>
        <p>
          Kontakta kioskansvariga Karin om ni har frågor, funderingar eller
          förslag angående kiosken.
        </p>
        <p>
          Anmäl gärna till kioskansvariga om ni har möjlighet att hjälpa till.
        </p>
        <p>
          Närvaroanmälan sker för närvarande i anslutning till kiosken innan
          körning påbörjas.
        </p>
        <router-link to="/narvaroanmalan" class="link-4">Läs mer om närvaroanmälan</router-link>
      </article>
      <article>
        <h3>Verkstad</h3>
        <p>Vi har även en verkstad där vi mekar med alla föreningens fordon.</p>
      </article>
      <article id="fordonspark">
        <h3>Fordonspark</h3>
        <p>
          Vi har ett 30-tal crossmaskiner och mopeder från 50cc till 150cc att
          låna och hyra.
        </p>
        <router-link to="/hire-vehicles" class="link-4">Läs mer om hyra av crossar</router-link>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurFacilityComp",
  mounted() {
    // Get current open site/route by this.$route.name,
    // then get header link element with that id and add class: "current-site" to that link
    if (this.$route.hash == null) {
      return;
    }
    let _hash = this.$route.hash;
    _hash = _hash.slice(1);

    const _thisSiteFocus = document.getElementById(_hash);
    if (_thisSiteFocus) {
      // If site exist in main nav
      _thisSiteFocus.classList.add("focused-part");
    }
  },
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style scoped>
section>.wrapper {
  gap: 3rem;
}

article {
  display: grid;
  gap: 0.5rem;
}
</style>
