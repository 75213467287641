<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Redigera nyheten: "{{ originalName }}"</h1>
    </div>
  </div>

  <section>
    <div class="form-1">
      <router-link :to="{ name: 'News' }" class="link-4">Gå till nyheter</router-link>

      <div class="input-1">
        <label for="header">Rubrik:</label>
        <input type="text" v-model="name" placeholder="Skriv en rubrik..." maxlength="50" id="header" />
      </div>
      <div class="input-1">
        <label for="text">Brödtext:</label>
        <WritingToolsComp :content="content" @change-content="content = $event" />
        <textarea v-model="content" placeholder="Berätta om nyheten..." class="min-height200" maxlength="2000"
          id="content"></textarea>
      </div>

      <fieldset class="written-result">
        <legend>Resultat:</legend>
        <div class="wrapper">
          <h3>{{ name }}</h3>
          <p v-for="paragraph in content.split('\n')" :key="paragraph" class="no-margin" v-html="paragraph"></p>
          <p class="cursive">
            Senast redigerad: {{ new Date().getDate() }}/{{
              new Date().getMonth() + 1
            }}-{{ new Date().getFullYear() }}
          </p>
        </div>
      </fieldset>

      <div class="edit-new__buttons mt-1">
        <button @click="$refs.deletePromptComp.toggleDeletePromt()" id="show-delete-prompt"
          class="font-size-1 button-1 danger">
          Radera nyhet
        </button>
        <button @click="updateNew()" class="font-size-1 button-1">
          Updatera nyhet
        </button>
      </div>
    </div>

    <DeletePromptComp ref="deletePromptComp" deleteThing="nyhet" @delete="deleteNew" />
  </section>
</template>

<script>
import DeletePromptComp from "@/components/DeletePromptComp.vue";
import WritingToolsComp from "@/components/WritingToolsComp.vue";

import { db } from "../firebase";
import {
  collection,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const newsCollectionRef = collection(db, "news");

export default {
  name: "NewsEditComp",
  components: {
    DeletePromptComp,
    WritingToolsComp,
  },

  created() {
    document.title = this.$route.meta.title;
  },
  async mounted() {
    await this.loadNewToEdit();
  },

  data() {
    return {
      originalName: "",
      id: "",
      name: "",
      content: "",
    };
  },

  methods: {
    async loadNewToEdit() {
      // const docRef = doc(this.newsCollectionRef, this.$route.params.id);
      const docRef = doc(newsCollectionRef, this.$route.params.id);
      const _new = await getDoc(docRef);

      if (_new.exists()) {
        this.id = _new.id;
        this.name = _new.data().name;
        this.originalName = _new.data().name;
        this.content = _new.data().content;
      } else {
        // doc.data() will be undefined in this case
        this.originalName = "Nyheten finns inte...";
        alert("Denna nyhet finns inte!");

        this.$router.push("/news");
      }
    },
    async updateNew() {
      const docRef = doc(newsCollectionRef, this.$route.params.id);

      const currentDate = new Date()
      await updateDoc(docRef, {
        name: this.name,
        content: this.content,
        updated_on: currentDate
      });

      this.originalName = this.name;
      alert(`Ändringarna på nyheten "${this.name}" har nu sparats.`);

      // Clear inputfields
      // this.name = '';
      // this.content = '';

      this.$router.push("/news");
    },
    async deleteNew() {
      const docRef = doc(newsCollectionRef, this.$route.params.id);
      await deleteDoc(docRef);

      this.originalName = "BORTTAGEN"; // If doesn't redirect user sees new is deleted in page hero
      alert(`NYHETEN "${this.name}" HAR NU RADERATS!`);

      this.$router.push("/news");
    },
  },
};
</script>
