<template>
  <div id="home-hero">
    <div class="content">
      <h1>Välkommen till Norra Gotlands MHF-Ungdom!</h1>
      <h3>
        Vår verksamhet ger <strong>ALLA</strong> möjligheten att köra cross,
        såväl nybörjare som proffs!
      </h3>
    </div>

    <router-link to="/#news" class="scroll-down-btn">
      Läs vidare
      <img src="../assets/svgs/down-arrow.svg" alt="Down arrow" />
    </router-link>
  </div>

  <div id="news">
    <router-link class="new-card" v-for="_new in newsList" :key="_new.id" :to="`/news/0#${_new.id}`">
      <h4>{{ _new.name }}</h4>
      <p class="new-card-content" v-html="_new.content"></p>
      <span class="link-4">Läs vidare</span>
    </router-link>

    <div class="new-card new-browse-all">
      <router-link :to="`/news/0`" class="button-1">Se alla nyheter</router-link>
    </div>
  </div>

  <section id="first-section" class="opening-hours">
    <div class="wrapper">
      <h2 id="opening-hours">Öppettider</h2>

      <p>
        Titta alltid
        <router-link to="/calendar" class="link-3">kalendern</router-link> och
        <router-link to="/news" class="link-3">nyheter</router-link> för att se
        om verksamhetens tider är ändrade och för få den senaste informationen.
      </p>

      <div>
        <p class="important">
          OBS! Måste ALLTID finnas
          <router-link to="/traningsansvarig" class="link-3">träningsansvarig</router-link>!
        </p>
        <!-- <router-link to="/traningsansvarig" class="link-4">Läs mer</router-link> -->
      </div>

      <div class="opening-hour__table">
        <h4>
          Om TRÄNINGSANSVARIG FINNS OCH INGET ANNAT ANGETS gäller öppettiderna
          nedan:
        </h4>

        <table>
          <tr>
            <td>Måndag</td>
            <td>17.00-20.30</td>
            <td v-if="isMondayActivity">
              <router-link to="/#monday-activities" class="link-3">Måndagsaktiviteter</router-link>
              från 18.00
            </td>
            <td v-if="!isMondayActivity">Endast egen mc</td>
          </tr>
          <tr>
            <td>Onsdag</td>
            <td>17.00-21.00</td>
            <td>Endast egen mc</td>
          </tr>
          <tr>
            <td>Lördag</td>
            <td>09.00-14.00</td>
            <td>Endast egen mc</td>
          </tr>
        </table>
      </div>

      <div>
        <p>Närvaro skall raporteras vid varje körtillfälle.</p>
        <router-link to="/narvaroanmalan" class="link-4">Läs mer om närvaroanmälan</router-link>
      </div>

      <p class="cursive no-margin">
        OBS! ALL ÖVRIG TID ÄR KÖRNING FÖRBJUDEN! Cementfabriken spränger nära banan
        vissa dagar och det är förenat med LIVSFARA att vistas i skogen
        "måndagar till fredagar kl. 07.00 - 16.00."
      </p>
    </div>
  </section>

  <section id="monday-activities">
    <div class="mb-2" v-if="$store.state.user">
      <input type="checkbox" name="is-monday-activity" id="is-monday-activity" v-model="isMondayActivity" />
      <label for="is-monday-activity">Måndagsaktiviteter aktivt?</label>
    </div>

    <div class="wrapper">
      <h2>
        <span :class="{ 'line-through': !isMondayActivity }">Måndagsaktiviteter</span>
        <span class="danger" v-if="!isMondayActivity">- Under Uppehåll</span>
      </h2>

      <div>
        <p>
          Måndagar 18:00-20:30 lånar vi ut och hyr ut crossar till medlemmar.
        </p>
        <router-link to="/our-facility#fordonspark" class="link-4">Vår fordonspark</router-link>
      </div>
      <p>
        Vanligtvis är även
        <router-link to="/our-facility#kiosk" class="link-3">kiosken</router-link>
        öppen, med servering i klubbstugan. Där finns kaffe, läsk, korv, godis
        mm.
      </p>

      <div>
        <p class="important">
          OBS! Uppehåll sker vanligen under vintern och sommaren, likt skolan.
        </p>
        <router-link to="/calendar" class="link-4">Läs kalendern</router-link>
      </div>
    </div>
  </section>

  <section>
    <div class="wrapper text-align-center">
      <h2>Hitta hit</h2>

      <router-link to="/info" class="link-4">Läs mer om våra uppgifter</router-link>
      <router-link to="/our-facility" class="link-4">Läs mer om vår anläggning</router-link>
    </div>
  </section>

  <section>
    <div class="wrapper">
      <h2>Träningsavgift</h2>

      <p>
        <strong>Träningsavgift ska betalas</strong> av alla som kör på
        endurobanan, inklusive jordbanedelen. Undantag gäller för medlemmar vid
        ledarledda aktiviteter (måndagsaktiviteterna samt läger) samt på vanlig
        träning på måndagar.
      </p>

      <ul>
        <li v-for="traningsavgift, i in landingPageData?.traningsavgift" :key="i">{{ traningsavgift }}</li>
      </ul>

      <div>
        <p class="important">
          OBS! Närvaroanmälan vid inskrivningsdatorn innan körning är
          obligatorisk för att försäkringarna ska gälla.
        </p>
        <router-link to="/narvaroanmalan" class="link-4">Läs mer om närvaroanmälan</router-link>
      </div>
    </div>
  </section>

  <section>
    <div class="wrapper text-align-center">
      <h2>Tävlingar</h2>
      <router-link to="/races" class="link-4">Kommande tävlingar</router-link>
      <router-link to="/results" class="link-4">Resultat tävlingar</router-link>
    </div>
  </section>
</template>

<script>
import { getDoc } from "../utils"

import { db } from "../firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  orderBy,
  limit,
} from "firebase/firestore";

const newsCollectionRef = collection(db, "news");
const newsCollectionQuery = query(
  newsCollectionRef,
  orderBy("created_on", "desc"),
  limit(3)
);

export default {
  name: "HomeComp",
  data() {
    return {
      landingPageData: {},
      isMondayActivityDBId: 0,
      isMondayActivity: null,
      newsList: [],
    };
  },
  watch: {
    isMondayActivity(_new, _old) {
      if (_old == null) {
        // Value is null on page load, makes watch look past first init of isMondayAcivity
        return;
      }

      // Extra confirm before update isMondayActivity on db
      let _mondayActivityActiveString = "Aktiv";
      if (!this.isMondayActivity) {
        _mondayActivityActiveString = "Under Uppehåll";
      }
      if (
        confirm(
          `Updatera status för Måndagsaktiviteter till ${_mondayActivityActiveString}?`
        )
      ) {
        // Set isMondayActivity in db
        this.setIsMondayActivityInDB();
      } else {
        // Refresh page to Fallback to old value
        this.$router.go(0);
      }
    },
  },
  created() {
    document.title = this.$route.meta.title;
  },
  async beforeMount() {
    await this.getNews();

    this.landingPageData = await getDoc("landing_page")
    this.isMondayActivity = this.landingPageData?.is_monday_activity
    console.log(this.landingPageData)
    this.isMondayActivityDBId = this.landingPageData.id;
  },
  methods: {
    async setIsMondayActivityInDB() {
      const docRef = doc(
        collection(db, "landing_page"),
        this.isMondayActivityDBId
      );
      await updateDoc(docRef, {
        is_monday_activity: this.isMondayActivity,
      });

      console.log(
        `Ändringarna på checkboxen isMondayActivity har nu sparats till: "${this.isMondayActivity}".`
      );
    },

    async getNews() {
      const querySnapshot = await getDocs(newsCollectionQuery);
      let _news = [];
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, "=> ", doc.data());
        const _new = {
          id: doc.id,
          name: doc.data().name,
          //   content: doc.data().content.slice(0, 197),
          content: doc.data().content,
        };

        // Add "..." if content is cant fit, to show there is more to read if "Läs vidare >" is pressed
        // if (_new.content.length >= 197) {
        //   _new.content = _new.content + "...";
        // }

        _news.push(_new);
      });
      this.newsList = _news;
    },
  },
};
</script>

<style scoped>
#news {
  width: 100%;
  padding: 4rem;
  box-sizing: border-box;

  display: grid;

  justify-content: space-evenly;
  justify-items: center;

  column-gap: 4rem;
  row-gap: 6rem;
  grid-template-columns: repeat(4, minmax(auto, 20rem));
}

#news .new-card {
  width: 100%;
  max-height: 200px;

  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

#news>.new-card h4 {
  margin: 0;
  height: 1.5em;
}

#news>.new-card .new-card-content {
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-browse-all {
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

#home-hero {
  min-width: 100%;
  min-height: 90vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url("../assets/images/hero-background-image.jpg");
  background-repeat: no-repeat;
  /* background-size: 110vw auto; */
  background-size: cover;
  background-position: center;
}

/* Hero mitten text v3 */
#home-hero .content {
  max-width: 90%;
  box-sizing: border-box;

  text-align: center;

  border-radius: 0.6rem;
  padding: 2rem 4rem;

  background-color: white;
}

#home-hero .content>* {
  margin: 1rem 0;
}

.opening-hours .wrapper {
  gap: 1.5rem;
}

.opening-hour__table>table {
  margin-top: 0.5rem;
}

td {
  padding: 0.5em;
}

/* On screens that are 600px or less */
@media screen and (max-width: 1500px) {
  #news {
    grid-template-columns: repeat(2, minmax(auto, 20rem));
  }

  .new-browse-all {
    align-items: start;
  }
}

@media screen and (max-width: 800px) {
  #news {
    grid-template-columns: repeat(1, minmax(auto, 20rem));
  }

  .new-browse-all {
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  #home-hero .content {
    padding: 1.5rem 2rem;
  }

  .new-browse-all {
    font-size: 1rem;
  }
}
</style>
