<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Hyra av fordon</h1>
      <p>
        Då vissa fordon kostar mer i underhåll för föreningen tar vi ut hyra för
        dem.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <div>
        <table>
          <h2>Våra fordon:</h2>
          <tr v-for="vehicle in vehicles" :key="vehicle.name">
            <td>{{ vehicle.amount }}x {{ vehicle.name }}</td>
            <td>- {{ vehicle.cost }}</td>
          </tr>
        </table>
      </div>
      <p class="important">
        OBS! Anpassade taxor gäller för läger och liknande.
      </p>
    </div>
  </section>
</template>

<script>
import { db } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

const vehicleCollectionRef = collection(db, "vehicles");
const vehicleCollectionQuery = query(vehicleCollectionRef, orderBy("name"));

export default {
  name: "HireVehiclesComp",
  created() {
    document.title = this.$route.meta.title;
  },

  data() {
    return {
      vehicles: []
    };
  },

  async beforeMount() {
    this.vehicles = await this.getVehicles();
  },

  methods: {
    async getVehicles() {
      const querySnapshot = await getDocs(vehicleCollectionQuery);

      let _vehicles = [];
      querySnapshot.forEach((doc) => {
        _vehicles.push(doc.data());
      });

      return _vehicles;
    }
  }
};
</script>
