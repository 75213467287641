import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import OurFacilityView from "@/views/OurFacilityView.vue";
import MemberView from "@/views/MemberView.vue";
import InfoView from "@/views/InfoView.vue";
import CalendarView from "@/views/CalendarView.vue";
import ImagesView from "@/views/ImagesView.vue";
import ImagesAlbumView from "@/views/ImagesAlbumView.vue";

// "Under pages" start
import RentHouseView from "@/views/RentHouseView.vue";
import TraningsansvarigView from "@/views/TraningsansvarigView.vue";
import NarvaroanmalanView from "@/views/NarvaroanmalanView.vue";
import SponsorsView from "@/views/SponsorsView.vue";
import LinksView from "@/views/LinksView.vue";
import HireVehiclesView from "@/views/HireVehiclesView.vue";
import LicensesView from "@/views/LicensesView.vue";

import RacesView from "@/views/RacesView.vue";
import RacesAddView from "@/views/RacesAddView.vue";
import RacesEditView from "@/views/RacesEditView.vue";

import ResultsView from "@/views/ResultsView.vue";

import NewsView from "@/views/NewsView.vue";
import NewsAddView from "@/views/NewsAddView.vue";
import NewsEditView from "@/views/NewsEditView.vue";

import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";

import PageNotFoundView from "@/views/PageNotFoundView.vue";

import { createRouter, createWebHistory } from "vue-router";

import { auth } from "../firebase";

const routes = [
  {
    name: "Home",
    component: HomeView,
    path: "/",
    meta: { title: "Hem - MHF Othem" },
  },
  {
    name: "About",
    component: AboutView,
    path: "/about",
    meta: { title: "Om oss - MHF Othem" },
  },
  {
    name: "OurFacility",
    component: OurFacilityView,
    path: "/our-facility",
    meta: { title: "Vår anläggning - MHF Othem" },
  },
  {
    name: "Member",
    component: MemberView,
    path: "/member",
    meta: { title: "Bli medlem - MHF Othem" },
  },
  {
    name: "Info",
    component: InfoView,
    path: "/info",
    meta: { title: "Uppgifter - MHF Othem" },
  },
  {
    name: "Calendar",
    component: CalendarView,
    path: "/calendar",
    meta: { title: "Kalender - MHF Othem" },
  },

  {
    name: "Images",
    component: ImagesView,
    path: "/images",
    meta: { title: "Bilder - MHF Othem" },
  },
  {
    name: "ImagesAlbum",
    component: ImagesAlbumView,
    path: "/album/:headCategory/:subCategory/:images",
    meta: { title: "Bildalbum - MHF Othem" },
  },

  // "Under pages" start
  {
    name: "RentHouse",
    component: RentHouseView,
    path: "/rent-house",
    meta: { title: "Hyra huset - MHF Othem" },
  },
  {
    name: "Traningsansvarig",
    component: TraningsansvarigView,
    path: "/traningsansvarig",
    meta: { title: "Träningsansvarig - MHF Othem" },
  },
  {
    name: "Narvaroanmalan",
    component: NarvaroanmalanView,
    path: "/narvaroanmalan",
    meta: { title: "Närvaroanmälan - MHF Othem" },
  },
  {
    name: "Sponsors",
    component: SponsorsView,
    path: "/sponsors",
    meta: { title: "Sponsorer - MHF Othem" },
  },
  {
    name: "Links",
    component: LinksView,
    path: "/links",
    meta: { title: "Länkar - MHF Othem" },
  },
  {
    name: "HireVehicles",
    component: HireVehiclesView,
    path: "/hire-vehicles",
    meta: { title: "Hyra fordon - MHF Othem" },
  },
  {
    name: "Licenses",
    component: LicensesView,
    path: "/licenses",
    meta: { title: "Licenser - MHF Othem" },
  },

  {
    name: "Races",
    component: RacesView,
    path: "/races",
    meta: { title: "Tävlingar - MHF Othem" },
  },
  {
    name: "RacesAdd",
    component: RacesAddView,
    path: "/races-add",
    meta: {
      title: "Lägg till tävling - MHF Othem",
      requiresAuth: true,
      noindex: true,
    },
  },
  {
    name: "RacesEdit",
    component: RacesEditView,
    path: "/races-edit/:id",
    meta: {
      title: "Redigera tävling - MHF Othem",
      requiresAuth: true,
      noindex: true,
    },
  },

  {
    name: "Results",
    component: ResultsView,
    path: "/results",
    meta: { title: "Resultat tävlingar - MHF Othem" },
  },

  {
    name: "News",
    component: NewsView,
    path: "/news",
    meta: { title: "Nyheter - MHF Othem" },
  },
  {
    name: "NewsPageSpecified",
    component: NewsView,
    path: "/news/:page",
    meta: { title: "Nyheter - MHF Othem" },
  },
  {
    name: "NewsAdd",
    component: NewsAddView,
    path: "/news-add",
    meta: {
      title: "Lägg till nyhet - MHF Othem",
      requiresAuth: true,
      noindex: true,
    },
  },
  {
    name: "NewsEdit",
    component: NewsEditView,
    path: "/news-edit/:id",
    meta: {
      title: "Redigera nyhet - MHF Othem",
      requiresAuth: true,
      noindex: true,
    },
  },

  {
    name: "Login",
    component: LoginView,
    path: "/login",
    meta: {
      title: "Logga in - MHF Othem",
      requiresNoAuth: true,
      noindex: true,
    },
  },

  {
    name: "Register",
    component: RegisterView,
    path: "/register",
    meta: {
      title: "Registrera - MHF Othem",
      requiresAuth: true,
      noindex: true,
    },
  },

  {
    name: "PageNotFound",
    component: PageNotFoundView,
    path: "/404",
    meta: {
      title: "Sidan kunde inte hittas - MHF Othem",
      noindex: true,
    },
  },
  // and finally the default route, when none of the above matches:
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (to.hash != "") {
        // if new route has #id, scroll to that id
        setTimeout(() => {
          resolve({ el: to.hash });
        }, 200);
      } else {
        // else scroll to top
        resolve(savedPosition || { top: 0 });
      }
    });
  },
});

function setNoIndexMetaTag(to) {
  const noindexMetaTag = document.querySelector('meta[name="robots"]');

  if (to.meta.noindex) {
    if (!noindexMetaTag) {
      // Create the tag if it doesn't exist
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex';
      document.head.appendChild(meta);
    }
  } else {
    if (noindexMetaTag) {
      // Remove the tag if it exists
      noindexMetaTag.remove();
    }
  }
}

router.beforeEach((to, from, next) => {
  setNoIndexMetaTag(to)

  if (
    to.matched.some((record) => record.meta.requiresNoAuth) &&
    auth.currentUser
  ) {
    next("/");
    return;
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !auth.currentUser
  ) {
    console.warn("Need to login");
    next("/login");
    return;
  }

  // If not catch, go on to wanted route
  next();
});

export default router;
