import { db } from "../firebase";
import { collection, getDocs, query } from "firebase/firestore";

export async function getDoc(collectionName) {
    const collectionRef = collection(db, collectionName);
    const collectionQuery = query(collectionRef);

    const querySnapshot = await getDocs(collectionQuery);

    let foundDoc;
    querySnapshot.forEach((doc) => {
        foundDoc = {id: doc.id, ...doc.data()};
    });

    return foundDoc;
  }