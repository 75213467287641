<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Träningsansvarig</h1>
      <p>
        För att körning ska få ske på banorna krävs det att det finns minst en
        träningsansvarig på plats.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <h2>Uppgifter:</h2>

      <ul>
        <li>Låsa upp och låsa till bom och toalett.</li>
        <li>
          Informera om uppsatta regler och att rapportera om dessa ej följs.
        </li>
        <li>
          Informera om att medlemskap i Svemo-ansluten klubb och licens krävs
          för körning.
          <router-link to="/licenses" class="link-4">Läs mer om licenser</router-link>
        </li>
        <li>
          Registrera närvaro.
          <router-link to="/narvaroanmalan" class="link-4">Läs mer om närvaroanmälan</router-link>
        </li>
        <li>
          Kontrollera att betalning av träningsavgift gjorts (swish-konto
          {{ uppgifter.swish }}, markera betalning med T för träning).
        </li>
        <li>Vara kontaktperson på plats i händelse av olycka.</li>
      </ul>

      <div>
        <h3 class="important">OBS! Träningsansvarig får ej köra själv.</h3>
        <p>
          Vi godkänner dock att träningsansvaret delas upp på fler under dagen,
          bara det tydligt framgår vem som är träningsansvarig för stunden genom
          användande av tilldelad väst. Observera dock att alla som kör måste
          betala träningsavgift.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { getDoc } from "../utils"

export default {
  name: "TraningsansvarigComp",
  created() {
    document.title = this.$route.meta.title;
  },

  data() {
    return {
      uppgifter: []
    };
  },

  async beforeMount() {
    this.uppgifter = await getDoc("uppgifter");
  }
};
</script>
