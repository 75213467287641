<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Uppgifter</h1>
      <div class="margin-top-bottom-02">
        <p>Här finns klubbens uppgifter.</p>
        <p>
          För mer information om föreningen och vår styrelse:
          <router-link to="/about" class="link-4">Om oss</router-link>
        </p>
      </div>
    </div>
  </div>

  <section class="section-table" id="first-section">
    <div class="wrapper">
      <table border="1">
        <tr>
          <td>Telefonnummer:</td>
          <td>{{ uppgifter.phone_number }}</td>
        </tr>
        <tr>
          <td>E-post:</td>
          <td>
            <a :href="'mailto:' + uppgifter.email">{{ uppgifter.email }}</a>
          </td>
        </tr>

        <tr>
          <td>Postadress:</td>
          <td>
            <p>{{ uppgifter.post_adress }}</p>
          </td>
        </tr>

        <tr>
          <td>Besöksadress:</td>
          <td>
            <p>{{ uppgifter.visitation_adress }}</p>
          </td>
        </tr>

        <tr>
          <td>Föreningsnummer:</td>
          <td>{{ uppgifter.f_reningsnummer }}</td>
        </tr>
        <tr>
          <td>Organisationsnummer:</td>
          <td>{{ uppgifter.organisationsnummer }}</td>
        </tr>

        <tr>
          <td>Bankgiro:</td>
          <td>{{ uppgifter.bankgiro }}</td>
        </tr>
        <tr>
          <td>Swish:</td>
          <td>{{ uppgifter.swish }}</td>
        </tr>
      </table>
    </div>
  </section>
  <section>
    <div class="wrapper">
      <h2>Swish</h2>
      <p>Du kan betala med SWISH hos oss. Telnr: {{ uppgifter.swish }}</p>
      <p>
        Skriv meddelande så att det går att spåra betalningen, t ex K för kiosk
        och tvätt, H för hyra, T för träningsavgift eller M och personnummer för
        medlemsskap.
      </p>
      <p>
        Vi har satt ett minibelopp på {{ uppgifter.min_swish }} kr p g a bankavgifterna per
        transaktion.
      </p>
    </div>
  </section>
</template>

<script>
import { getDoc } from "../utils"

export default {
  name: "InfoComp",
  created() {
    document.title = this.$route.meta.title;
  },

  data() {
    return {
      uppgifter: []
    };
  },

  async beforeMount() {
    this.uppgifter = await getDoc("uppgifter");
  }
};
</script>

<style>
table {
  border-collapse: collapse;
  border-color: var(--gray2);
}

td {
  padding: 1em;
}

tr td:first-child {
  font-weight: bold;
}
</style>
